<template>
    <data-loading-component v-if="dataLoading"/>
    <div v-else>
        <contact-banner v-if="details.banner" :details="details.banner"></contact-banner>
        <div v-if="details" class="container py-4">
            <div class="ml-lg-5 mr-lg-8 py-4">
                <div class="row">
                    <div class="col-12 mb-4">
                        <base-breadcrumb :path="['News', details.news.title]"
                                         class=""></base-breadcrumb>
                    </div>
                    <div class="col-12">
                        <img v-if="details.news.image" :src="details.news.image" alt="" class="article-img">
                        <div class="ml-3 mt-3">
                            <div class="mb-2">
                                <span class="text-secondary font-lato-medium text-uppercase fs--1 mr-2">{{
                                        details.news.category
                                    }}</span>
                            </div>
                            <h6 class="font-weight-bold font-jost-semi fs-lg-9">{{ details.news.title }}</h6>
                            <p class="fs--1 mt-3 font-lato-medium align-justify"> {{
                                    details.news.full_description
                                }} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="details" class="bg-4 py-4">
            <div class="container" v-if="details.related_news.length!==0">
                <h3 class="fs-5 font-inter-medium mb-4">Related News</h3>
                <div class="row">
                    <div v-for="(item,i) in details.related_news" :key="i" class="col-12 col-lg-4 col-md-6">
                        <news-card @details="viewDetails"
                                   :id="item.id.toString()"
                                   :hour="item.created_at"
                                   :heading="item.title"
                                   :content="item.full_description.substring(0, 140)+' ...'"
                                   :img="item.image"
                                   :categ="item.category"
                        ></news-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseBreadcrumb from '../../components/ui/BaseBreadcrumb';
import NewsCard from '../../components/home/news/NewsCard';
import axios from 'secure-axios';
import urls from '../../data/urls';
import DataLoadingComponent from '../../components/ui/DataLoadingComponent';
import ContactBanner from '../../components/contact-us/Contact-Banner';

export default {
    name: 'NewsArticlePage',
    components: { ContactBanner, DataLoadingComponent, NewsCard, BaseBreadcrumb },
    props: {
        categ: {
            type: String,
            default: 'All'
        },
        heading: {
            type: String,
            default: 'Heading'
        },
        content: {
            type: String,
            default: 'Content'
        }
    },
    data () {
        return {
            details: '',
            id: this.$route.params.id,
            dataLoading: false
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        viewDetails (response) {
            if (response) {
                this.id = response;
            }
            this.loadDetails();
        },
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.form(urls.website.newsDetails, { id: this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        }
    }
};
</script>

<style scoped lang="scss">
.article-img {
    width: 100%;;
    position: relative;
    @media (min-width: 992px) {
        height: 60vh !important;
    }
}
</style>
